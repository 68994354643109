import React from 'react';
import { signOut } from 'next-auth/react';
import { Logout } from '@mui/icons-material';
import { Box, Divider, List, ListItemIcon } from '@mui/material';

import { StyledListItemButton, StyledListItemText } from '../elements';

interface SignOutProps {
  open?: boolean;
}

export function SignOut({ open }: SignOutProps) {
  return (
    <Box
      sx={{
        flexGrow: 0,
        width: '100%',
        marginBottom: theme => theme.spacing(2),
      }}
    >
      <Divider />
      <List>
        <StyledListItemButton
          sx={{
            '&:hover': {
              color: theme => theme.palette.error.main,
              '.logout-icon': { color: theme => theme.palette.error.main },
            },
          }}
          onClick={() => signOut()}
        >
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <ListItemIcon
              className="logout-icon"
              sx={{
                minWidth: 'inherit',
                marginTop: '-3px',
                color: theme => theme.palette.grey[400],
                transition: theme =>
                  theme.transitions.create('color', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.short,
                  }),
              }}
            >
              <Logout />
            </ListItemIcon>
            <StyledListItemText open={open}>Sair</StyledListItemText>
          </Box>
        </StyledListItemButton>
      </List>
    </Box>
  );
}

export default SignOut;
