import React from 'react';
import Link from 'next/link';
import { ChevronRight } from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';

import { SubMenuItem } from '~/types/constants/menu';

import { StyledListItemButton, StyledListItemText } from '../elements';
import { useSubmenuState } from '../hooks/useSubmenuState';

interface SubItemMenuProps {
  subItem: SubMenuItem;
  pathname: string;
}

export function SubItemMenu({ subItem, pathname }: SubItemMenuProps) {
  const { toggleSubmenu } = useSubmenuState();

  return (
    <Link href={subItem.href} legacyBehavior key={subItem.label}>
      <StyledListItemButton
        className={pathname === subItem.href ? 'active' : ''}
        onClick={() => toggleSubmenu(subItem.label)}
        disabled={subItem.disabled}
      >
        <StyledListItemText inset>{subItem.label}</StyledListItemText>
        <ListItemIcon
          sx={{
            minWidth: 'inherit',
            color: 'currentcolor',
            textAlign: 'right',
          }}
        >
          <ChevronRight />
        </ListItemIcon>
      </StyledListItemButton>
    </Link>
  );
}
export default SubItemMenu;
