const labelMenu = {
  dashboard: 'Dashboard',
  campaign: 'Campanhas',
  advertisers: 'Anunciantes',
  reports: 'Relatórios',
  users: 'Usuários',
};

const submenu = {
  banners: 'Banners',
  sponsored: 'Patrocinados',
  actives: 'Ativas',
};

export { labelMenu, submenu };
