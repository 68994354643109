'use client';

import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { useSearchParams } from 'next/navigation';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDate } from 'date-fns';

import {
  CampaignList,
  StatusSelect,
  useBreadcrumbs,
  useGetCampaigns,
} from '~/entities';
import {
  ContentTitle,
  datePickerSlotProps,
  Pagination,
  SearchBox,
  StyledContainer,
  StyledTableContainer,
} from '~/shared';
import { CampaignListDTO, CampaignStatus } from '~/types';

const CAMPAIGNS_PAGE_SIZE = 15;

export function CampanhasView() {
  const breadcrumbs = useBreadcrumbs();

  const searchParams = useSearchParams();
  const advertiserSearch = searchParams.get('advertiserUrl') ?? '';

  const [campaignQuery, setCampaignQuery] = useState<string>(
    `${advertiserSearch}`
  );
  // const [campaignQuery, setCampaignQuery] = useState<string>(``);
  const [status, setStatus] = useState<'' | `${CampaignStatus}`>('');

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);

  const [startDateError, setStartDateError] = useState('');
  const [finalDateError, setFinalDateError] = useState('');
  const [sortByOrderId, setSortByOrderId] = useState<'asc' | 'desc' | false>(
    false
  );
  const [sortByOrderCampaign, setSortByOrderCampaign] = useState<
    'asc' | 'desc' | false
  >(false);
  const [sortByOrderDevice, setSortByOrderDevice] = useState<
    'asc' | 'desc' | false
  >(false);
  const [sortByOrderInitial, setSortByOrderInitial] = useState<
    'asc' | 'desc' | false
  >(false);
  const [sortByOrderFinish, setSortByOrderFinish] = useState<
    'asc' | 'desc' | false
  >(false);

  const sortConfig = {
    sortByOrderId,
    setSortByOrderId,
    sortByOrderCampaign,
    setSortByOrderCampaign,
    sortByOrderDevice,
    setSortByOrderDevice,
    sortByOrderInitial,
    setSortByOrderInitial,
    sortByOrderFinish,
    setSortByOrderFinish,
  };

  const [campaignsFilter, setCampaignsFilter] = useState<CampaignListDTO>({
    query: campaignQuery,
    count: CAMPAIGNS_PAGE_SIZE,
    status: status === '' ? undefined : status,
    page: 1,
  });

  const useGetCampaignsQuery = useGetCampaigns(campaignsFilter);

  const total = useGetCampaignsQuery.data?.total ?? 0;
  const totalPages = Math.ceil(total / CAMPAIGNS_PAGE_SIZE);

  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current) {
      breadcrumbs.setPaths([{ label: 'Campanhas', path: '/campanhas' }]);
      useGetCampaignsQuery.refetch();
      hasInitialized.current = true;
    }
  }, [breadcrumbs, useGetCampaignsQuery]);

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        query: campaignQuery,
        page: 1,
      }));
    },
    500,
    [campaignQuery]
  );

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        status: status === '' ? undefined : status,
        page: 1,
      }));
    },
    1,
    [status]
  );

  useDebounce(
    () => {
      if (startDate === null) {
        setStartDateError('');
        setCampaignsFilter(state => ({
          ...state,
          startDate: undefined,
          page: 1,
        }));
        return;
      }

      if (Number.isNaN(startDate.getTime())) {
        setStartDateError('Data invalida');
        return;
      }

      setStartDateError('');
      setCampaignsFilter(state => ({
        ...state,
        startDate: formatDate(startDate, 'dd-MM-yyyy'),
        page: 1,
      }));
    },
    1,
    [startDate]
  );

  useDebounce(
    () => {
      setFinalDateError('');
      if (finalDate === null) {
        setCampaignsFilter(state => ({
          ...state,
          finishDate: undefined,
          page: 1,
        }));
        return;
      }

      if (Number.isNaN(finalDate.getTime())) {
        setFinalDateError('Data invalida');
        return;
      }

      setFinalDateError('');
      setCampaignsFilter(state => ({
        ...state,
        finishDate: formatDate(finalDate, 'dd-MM-yyyy'),
        page: 1,
      }));
    },
    1,
    [finalDate]
  );

  function nextPageHandler() {
    if (campaignsFilter.page < totalPages) {
      setCampaignsFilter(state => ({
        ...state,
        page: state.page + 1,
      }));
    }
  }

  function prevPageHandler() {
    if (campaignsFilter.page > 1) {
      setCampaignsFilter(state => ({
        ...state,
        page: state.page - 1,
      }));
    }
  }

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        orderId: sortByOrderId,
      }));
    },
    1,
    [sortByOrderId]
  );

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        orderCampaign: sortByOrderCampaign,
      }));
    },
    1,
    [sortByOrderCampaign]
  );

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        orderDevice: sortByOrderDevice,
      }));
    },
    1,
    [sortByOrderDevice]
  );

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        orderInitial: sortByOrderInitial,
      }));
    },
    1,
    [sortByOrderInitial]
  );

  useDebounce(
    () => {
      setCampaignsFilter(state => ({
        ...state,
        orderFinish: sortByOrderFinish,
      }));
    },
    1,
    [sortByOrderFinish]
  );

  return (
    <>
      <ContentTitle
        title="Campanhas"
        isFetching={
          useGetCampaignsQuery.isLoading || useGetCampaignsQuery.isFetching
        }
      />

      <StyledContainer>
        <Box
          sx={theme => ({
            display: 'flex',
            gap: 2,
            mb: 4,
            [theme.breakpoints.down('lg')]: { flexDirection: 'column' },
          })}
        >
          <SearchBox
            label="Procurar por ID, Anunciante ou Campanha"
            onChange={setCampaignQuery}
            value={campaignQuery}
            fullWidth
          />
          <Box
            sx={theme => ({
              display: 'flex',
              gap: 2,
              width: '100%',
              [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
            })}
          >
            <StatusSelect
              fullWidth
              growAtLg
              selectedStatus={status}
              setter={setStatus}
            />

            <DatePicker
              sx={{ width: '100%' }}
              label="Início"
              value={startDate}
              onChange={value => setStartDate(value)}
              slotProps={datePickerSlotProps(startDateError)}
            />

            <DatePicker
              sx={{ width: '100%' }}
              label="Término"
              value={finalDate}
              onChange={value => setFinalDate(value)}
              slotProps={datePickerSlotProps(finalDateError)}
            />
          </Box>
        </Box>

        <StyledTableContainer>
          <CampaignList
            useGetCampaignsQuery={useGetCampaignsQuery}
            sortConfig={sortConfig}
          />
        </StyledTableContainer>

        <Pagination
          disabled={
            totalPages === 1 || useGetCampaignsQuery.isFetching || total === 0
          }
          page={campaignsFilter.page}
          count={totalPages}
          nextPage={nextPageHandler}
          prevPage={prevPageHandler}
          onChange={(_, newPage) =>
            setCampaignsFilter(state => ({ ...state, page: newPage }))
          }
        />
      </StyledContainer>
    </>
  );
}
