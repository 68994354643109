import { TableCell, TableRow } from '@mui/material';

import { PlatformBadge, ProcessedDate, StyledIdTableCell } from '~/shared';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ActiveCampaignRow({ banner }: any) {
  return (
    <TableRow
      key={banner.campaignName}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        },
        '.MuiTableCell-body': {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <StyledIdTableCell component="th" scope="row" align="center">
        {banner.campaignId}
      </StyledIdTableCell>
      <TableCell component="th" scope="row">
        {banner.campaignName}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <PlatformBadge
          isApp={banner.isWebCampaign ? 'APP' : banner.isWebCampaign}
          isWeb={banner.isWebCampaign ? 'DESKTOP' : banner.isWebCampaign}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {banner.valueTotalInvestment}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {banner.startDate ? <ProcessedDate date={banner.startDate} /> : '-'}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {banner.endDate ? <ProcessedDate date={banner.endDate} /> : '-'}
      </TableCell>
    </TableRow>
  );
}
