'use client';

import { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControlLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  StyledCampaignFormLabelTitle,
  StyledCampaignFormRow,
  useBreadcrumbs,
} from '~/entities';
import { ActiveCampaignRow } from '~/entities/ActivesCampaigns';
import {
  ContentTitle,
  DefaultSwitch,
  StyledContainer,
  StyledHeaderTableCell,
  StyledTable,
  StyledTableContainer,
} from '~/shared';

import { LoadingActivesCampaignsView } from './loading';

export interface Banner {
  campaignName: string;
  isWebCampaign: boolean;
  isAppCampaign: boolean;
  startDate: string;
  endDate: string;
  bannerImage: string;
  bannerLink: string;
  bannerValueInvestment: number;
  valueTotalInvestment: number;
  campaignId: string;
  advertiserName: string;
}
interface ActiveCampaignProps {
  formatName: string;
  keyName: string;
  banners: Banner[];
}

export function ActiveCampaignsView() {
  const breadcrumbs = useBreadcrumbs();

  const [isLoading, setIsLoading] = useState(true);

  const [camp, setCamp] = useState<ActiveCampaignProps[]>([]);

  const [campaignActive, setCampaignActive] = useState<
    { id: number; active: boolean }[]
  >([]);

  const hasInitialized = useRef(false);
  useEffect(() => {
    if (!hasInitialized.current) {
      breadcrumbs.setPaths([
        { label: 'Campanhas Ativas', path: '/campanhas-ativas' },
      ]);
      hasInitialized.current = true;
    }
  }, [breadcrumbs]);

  useEffect(() => {
    function getCampaign() {
      fetch(
        `${process.env.NEXT_PUBLIC_SERVICE_API_URL}/api/v1/Campanha/ActiveCampaigns`
      )
        .then(response => response.json())
        .then(actives => {
          setCamp(actives);
          setCampaignActive(
            Array.from({ length: actives.length }, (_, index) => ({
              id: index,
              active: false,
            }))
          );
          setIsLoading(false);
        });
    }

    getCampaign();
  }, []);

  return (
    <>
      <ContentTitle title="Campanhas Ativas" isFetching={isLoading} />
      {!isLoading ? (
        <StyledContainer>
          {camp.map((campaign, index) => (
            <Box key={campaign?.formatName} sx={{ marginBottom: 3 }}>
              <StyledCampaignFormRow key={campaign?.formatName}>
                <FormControlLabel
                  control={<DefaultSwitch />}
                  label={
                    <StyledCampaignFormLabelTitle>
                      {campaign?.formatName}
                    </StyledCampaignFormLabelTitle>
                  }
                  checked={campaignActive[index]?.active}
                  onChange={(_, checked) =>
                    setCampaignActive(prev =>
                      prev.map(item =>
                        item.id === index ? { ...item, active: checked } : item
                      )
                    )
                  }
                />
              </StyledCampaignFormRow>
              {campaignActive[index]?.active && (
                <StyledTableContainer>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <StyledHeaderTableCell
                          sx={{ width: '60px' }}
                          align="center"
                        >
                          ID
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell
                          sx={{
                            minWidth: '200px',
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'background.paper',
                          }}
                        >
                          Anunciante / Campanha
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Plataforma
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Investimento
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Início
                        </StyledHeaderTableCell>
                        <StyledHeaderTableCell align="center">
                          Término
                        </StyledHeaderTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaign?.banners?.length === 0 &&
                        !campaign.banners[0]?.campaignId && (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              Sem Banners
                            </TableCell>
                          </TableRow>
                        )}
                      {campaign?.banners.map(banner => (
                        <ActiveCampaignRow
                          key={banner.campaignId}
                          banner={banner}
                        />
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              )}
            </Box>
          ))}
        </StyledContainer>
      ) : (
        <LoadingActivesCampaignsView />
      )}
    </>
  );
}
