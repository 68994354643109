import {
  AnunciantesIcon,
  CampanhasIcon,
  DashboardIcon,
  GerenciamentoIcon,
  RelatoriosIcon,
} from '~/shared';
import { labelMenu, submenu } from '~/types/constants/labelMenu';

export const navList = [
  {
    label: labelMenu.dashboard,
    icon: <DashboardIcon />,
    href: '/dashboard',
  },
  {
    label: labelMenu.campaign,
    icon: <CampanhasIcon />,
    href: '/campanhas',
    submenu: [
      {
        label: submenu.actives,
        href: '/campanhas-ativas',
      },

      { label: submenu.banners, href: '/campanhas' },
      {
        label: submenu.sponsored,
        href: '/campanhas/patrocinados',
        disabled: true,
      },
    ],
  },
  {
    label: labelMenu.advertisers,
    icon: <AnunciantesIcon />,
    href: '/anunciantes',
  },
  {
    label: labelMenu.reports,
    icon: <RelatoriosIcon />,
    href: '/relatorios',
  },
  {
    label: labelMenu.users,
    icon: <GerenciamentoIcon />,
    href: '/gerenciamento',
  },
];
