import { useState } from 'react';

export function useSubmenuState() {
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([]);

  function toggleSubmenu(label: string) {
    setOpenSubmenus(prev => {
      if (label === 'Campanhas' && !prev.includes('Campanhas')) {
        return [...prev, 'Campanhas', 'Banners'];
      }
      if (label === 'Campanhas' && prev.includes('Campanhas')) {
        return prev.filter(item => item !== 'Campanhas' && item !== 'Banners');
      }
      return prev.includes(label)
        ? prev.filter(item => item !== label)
        : [...prev, label];
    });
  }

  function setSubmenuState(labels: string[]) {
    setOpenSubmenus(labels);
  }

  return { openSubmenus, toggleSubmenu, setSubmenuState };
}
