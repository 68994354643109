import React, { useEffect } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, List, ListItemIcon } from '@mui/material';

import { labelMenu } from '~/types/constants/labelMenu';
import { MenuItem } from '~/types/constants/menu';
import roles from '~/types/constants/roles';

import { StyledListItemButton, StyledListItemText } from '../elements';
import { useSubmenuState } from '../hooks/useSubmenuState';

import { SubItemMenu } from './SubItemMenu';

interface ItemMenuProps {
  item: MenuItem;
  toggleMenu: () => void;
  isMobile: boolean;
  open?: boolean;
  isMenuOpen: boolean;
}

export function ItemMenu({
  item,
  toggleMenu,
  isMobile,
  open,
  isMenuOpen,
}: ItemMenuProps) {
  const pathname = usePathname();
  const { data: session } = useSession();
  const role = session?.user?.role;
  const { submenu, label, icon, href } = item;
  const hasSubMenu = submenu && submenu.length > 0;
  const { openSubmenus, toggleSubmenu, setSubmenuState } = useSubmenuState();

  const isSubmenuOpen = openSubmenus.includes(label);

  useEffect(() => {
    if (pathname === '/campanhas') {
      setSubmenuState(['Campanhas', 'Banners']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function handleNavClick() {
    if (isMobile || window.innerWidth < 600) {
      toggleMenu();
    }
  }

  if (label === labelMenu.users) {
    if (role === roles[0] || role === roles[2]) {
      return null;
    }
  }

  return (
    <Box key={label}>
      <Link href={href} legacyBehavior key={item.label}>
        <StyledListItemButton
          className={pathname === href ? 'active' : ''}
          onClick={!hasSubMenu ? handleNavClick : undefined}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              width: '100%',
            }}
          >
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: 'inherit',
                  marginTop: '-3px',
                  color: 'currentcolor',
                }}
              >
                {icon}
              </ListItemIcon>
            )}

            <StyledListItemText open={open} inset={!icon}>
              {label}
            </StyledListItemText>
          </Box>
          {hasSubMenu && isMenuOpen && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                toggleSubmenu(label);
              }}
              sx={{
                marginLeft: 'auto',
                color: 'inherit',
                padding: 0,
              }}
            >
              {isSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </StyledListItemButton>
      </Link>
      {hasSubMenu && isMenuOpen && (
        <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {submenu && (
              <List component="div" disablePadding>
                {submenu.map(subItem => (
                  <SubItemMenu
                    key={subItem.href}
                    subItem={subItem}
                    pathname={pathname}
                  />
                ))}
              </List>
            )}
          </List>
        </Collapse>
      )}
    </Box>
  );
}
export default ItemMenu;
